import Vue from 'vue'
import axios from 'axios'
import router from '../../router'

// Initial state
const state = {
    calls: {
        default: {
            pending: false,
            success: false,
            message: '',
            payload: [],
            total: 0
        }
    }
}

// https://itnext.io/reusable-vuex-mutation-functions-9b4920aa737b

// Mutations
const mutations = {
    reset(state) {
        Vue.set(state, 'calls', {
            default: {
                pending: false,
                success: false,
                message: '',
                payload: [],
                total: 0
            }
        })
    },
    api_pending(state, call) {
        Vue.set(state.calls, call.name, {
            pending: true,
            success: false,
            message: '',
            payload: [],
            total: 0
        })
    },
    api_success(state, call) {
        Vue.set(state.calls, call.name, {
            pending: false,
            success: true,
            message: call.data.description,
            payload: call.data.payload,
            total: call.data.total,
        })
    },
    api_failure(state, call) {
        Vue.set(state.calls, call.name, {
            pending: false,
            success: false,
            message: call.message,
            payload: call.payload,
            total: 0
        })
    },
}

// Actions
const actions = {
    reset({ commit }) {
        commit('reset')
    },
    call({ commit, rootState, dispatch }, args) {
        const name = args.name ? args.name : 'default'

        commit('api_pending', { name: name })

        return axios.request({
            method: args.method ? args.method : 'get',
            url: args.url,
            params: args.params,
            data: args.data
        })
            .then(response => {
                commit('api_success', { name: name, data: response.data })

                return response
            })
            .catch(error => {
                const payload = {
                    status: error.response.status || error.status,
                    name: error.response.data.name || error.name,
                    description: error.response.data.description || error.message
                }
                commit('api_failure', { name: name, message: payload.message, payload: payload })
                

                if (error.request.status == 401) {
                    router.push({ path: `/logout` })
                } else if (error.request.status === 403) {
                    router.push({ path: `/otp` })
                }

                return Promise.reject(payload.message)
            })
    }
}

const namespaced = true;

export default {
    namespaced,
    state,
    actions,
    mutations
}