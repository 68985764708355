import Vue from 'vue'
import axios from 'axios'

// Initial state
const state = {
    name: '',
    email: '',
    avatar: '',
    rules: {},
    pending: false,
    storage: {}
}

// Getters
const getters = {
    name: state => state.name,
    email: state => state.email,
    credits: state => state.credits,
    avatar: state => state.avatar,
    rules: state => state.rules,
}

// Mutations
const mutations = {
    login(state) {
        state.pending = true
    },

    login_success(state, payload) {
        state.name = payload.name
        state.email = payload.email
        state.credits = payload.credits
        state.avatar = payload.avatar
        state.rules = payload.rules
        state.pending = false
        state.storage = payload.storage
    },

    logout(state) {
        state.name = ''
        state.email = ''
        state.credits = ''
        state.avatar = ''
        state.rules = {}
        state.pending = false
        state.storage = {}
    },

    storage(state, storage) {
        var name = storage.name
        delete storage.name

        Vue.set(state.storage, name, { ...state.storage[name], ...storage })
    },

    account(state, payload) {
        state.name = payload.name
        state.email = payload.email
        state.avatar = payload.avatar
    },

    credits(state, amount) {
        state.credits = amount
    }
}

// Actions
const actions = {
    login({ commit }) {
        commit('login')
    },

    login_success({ commit }, payload) {
        commit('login_success', payload)
    },

    logout({ commit }) {
        commit('logout')
    },

    storage({ commit }, args) {
        commit('storage', args)
    },

    account({ commit }, payload) {
        commit('account', payload)
    },

    credits({ commit }, amount) {
        commit('credits', amount)
    }
}

const namespaced = true;

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}