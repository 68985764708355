let cfg

switch (document.location.hostname) {
    case 'allthingstalk.simservices.com':
        cfg = {
            title: 'AllThingsTalk Dashboard',
            bar_title: 'Dashboard',
            support: {
                email: 'support@allthingstalk.com',
                tel: '+32 468 12 47 39'
            },
            origin: 'allthingstalk',
            new_account: {
                smartsim: true
            },
            theme: {
                folder: 'allthingstalk',
                google_font: 'Lato:100,300,400,700,900',
                app_bar: {
                    background: '#FF855A',
                    text: 'white--text'
                },
                logo: {
                    filename: 'logo.svg',
                    width: 120,
                    height: 31
                },
                vuetify: {
                    themes: {
                        light: {
                            primary: '#FF855A',
                            secondary: '#56646D'
                        },
                        dark: {
                            primary: '#FF855A',
                            secondary: '#56646D'
                        }
                    }
                }
            }
        }
        break
    case 'old.simservices.com':
        cfg = {
            title: 'Sim Services Dashboard',
            bar_title: 'Dashboard',
            support: {
                email: 'support@simservices.com',
                tel: '+31 (0)88 035 0000'
            },
            origin: 'default',
            new_account: {
                smartsim: false
            },
            theme: {
                folder: 'simservices',
                google_font: 'Muli:100,300,400,500,700,900',
                app_bar: {
                    background: '#FED633',
                    text: 'blue-grey--text text--darken-4'
                },
                logo: {
                    filename: 'logo.svg',
                    width: 42,
                    height: 30
                },
                vuetify: {
                    themes: {}
                }
            }
        }
        break
    default:
        cfg = {
            title: 'Wireless Logic Dashboard',
            bar_title: 'Dashboard',
            support: {
                email: 'support.bnx@wirelesslogic.com',
                tel: '+31 (0)40 8 489 489'
            },
            origin: 'wirelesslogic',
            new_account: {
                smartsim: false
            },
            theme: {
                folder: 'wirelesslogic',
                google_font: 'Muli:100,300,400,500,700,900',
                app_bar: {
                    background: '#2A3B4D',
                    text: 'white--text'
                },
                logo: {
                    filename: 'logo.png',
                    width: 69,
                    height: 30
                },
                vuetify: {
                    themes: {
                        light: {
                            primary: '#1AB394',
                            secondary: '#56646D'
                        },
                        dark: {
                            primary: '#1AB394',
                            secondary: '#56646D'
                        }
                    }
                }
            }
        }
        break
}

export { cfg }

export default {
    install(Vue) {
        Vue.cfg = cfg
        Vue.prototype.$cfg = cfg
    }
}
