import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    // Account routes
    {
        name: 'login',
        path: '/login/:segment?',
        component: () => import(/* webpackChunkName: "views" */ '../views/account/login.vue'),
    },
    {
        name: 'logout',
        path: '/logout',
        beforeEnter(to, from, next) {
            store.dispatch('api/call', {
                url: '/authorize/logout',
                method: 'post',
            })
            store.dispatch('session/logout')
            next('/login')
        },
    },
    {
        name: 'otp',
        beforeEnter: requireAuth,
        path: '/otp',
        component: () => import(/* webpackChunkName: "views" */ '../views/account/otp.vue'),
    },
    {
        name: 'activate',
        path: '/activate',
        component: () => import(/* webpackChunkName: "views" */ '../views/account/activate.vue'),
        beforeEnter(to, from, next) {
            !to.query.token ? next('/login') : next()
        },
    },
    {
        name: 'reset',
        path: '/reset',
        component: () => import(/* webpackChunkName: "views" */ '../views/account/reset.vue'),
        beforeEnter(to, from, next) {
            !to.query.token ? next('/login') : next()
        },
    },
    {
        name: '403',
        path: '/403',
        component: () => import(/* webpackChunkName: "views" */ '../views/403.vue'),
    },

    // Dashboard routes
    {
        path: '/',
        beforeEnter: requireAuth,
        component: () => import(/* webpackChunkName: "views" */ '../views/layout.vue'),
        children: [
            {
                name: 'account',
                path: '/account',
                component: () => import(/* webpackChunkName: "views" */ '../views/account/edit.vue'),
                meta: { text: 'Account', icon: 'mdi-account', divider: true },
            },
            {
                name: 'root',
                path: '/root',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'Captain Root', icon: 'mdi-pirate', active: false },
                children: [
                    {
                        name: 'root_users',
                        path: '/root/users',
                        component: () => import(/* webpackChunkName: "views" */ '../views/root/users.vue'),
                        meta: { text: 'Users', icon: 'mdi-account-group' },
                    },
                    {
                        name: 'root_roles',
                        path: '/root/roles',
                        component: () => import(/* webpackChunkName: "views" */ '../views/root/roles.vue'),
                        meta: { text: 'Roles', icon: 'mdi-ship-wheel' },
                    },
                    {
                        name: 'root_views',
                        path: '/root/views',
                        component: () => import(/* webpackChunkName: "views" */ '../views/root/views.vue'),
                        meta: { text: 'Views', icon: 'mdi-island' },
                    },
                ],
            },

            {
                name: 'orders_customers',
                path: '/customers',
                component: () => import(/* webpackChunkName: "views" */ '../views/customers.vue'),
                meta: { text: 'Customers', icon: 'mdi-account-box-multiple' },
            },

            {
                name: 'bulk_changes',
                path: '/changes',
                component: () => import(/* webpackChunkName: "views" */ '../views/changes.vue'),
                meta: { text: 'Bulk Changes', icon: 'mdi-file-edit' },
            },

            {
                name: 'orders',
                path: '/orders',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'SIM Orders', icon: 'mdi-sim', active: false },
                children: [
                    {
                        name: 'orders_new',
                        path: '/orders/new',
                        component: () => import(/* webpackChunkName: "views" */ '../views/orders/new.vue'),
                        meta: { text: 'New Order', icon: 'mdi-package-variant' },
                    },
                    {
                        name: 'orders_overview',
                        path: '/orders/overview',
                        component: () => import(/* webpackChunkName: "views" */ '../views/orders/overview.vue'),
                        meta: { text: 'Overview', icon: 'mdi-view-list' },
                    },
                    // This is for tne new web orders
                    // {
                    //     name: 'web_orders',
                    //     path: '/order/weborders',
                    //     component: () => import(/* webpackChunkName: "views" */ '../views/orders/weborders.vue'),
                    //     meta: { text: 'Web Orders', icon: 'mdi-web' },
                    // },
                    {
                        name: 'orders_subscriptions',
                        path: '/orders/subscriptions',
                        component: () => import(/* webpackChunkName: "views" */ '../views/orders/subscriptions.vue'),
                        meta: { text: 'Subscriptions', icon: 'mdi-text-box-multiple' },
                    },
                ],
            },
            {
                name: 'orders_products',
                path: '/products',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'Product Orders', icon: 'mdi-shopping', active: false, divider: true },
                children: [
                    {
                        name: 'procuts_new',
                        path: '/products/new',
                        component: () => import(/* webpackChunkName: "views" */ '../views/products/new.vue'),
                        meta: { text: 'New Order', icon: 'mdi-package-variant' },
                    },
                    {
                        name: 'products_overview',
                        path: '/products/overview',
                        component: () => import(/* webpackChunkName: "views" */ '../views/products/overview.vue'),
                        meta: { text: 'Overview', icon: 'mdi-view-list' },
                    },
                    {
                        name: 'products_subscriptions',
                        path: '/products/subscriptions',
                        component: () => import(/* webpackChunkName: "views" */ '../views/products/subscriptions.vue'),
                        meta: { text: 'Subscriptions', icon: 'mdi-text-box-multiple' },
                    },
                ],
            },
            {
                name: 'home',
                path: '/',
                component: () => import(/* webpackChunkName: "views" */ '../views/home.vue'),
                meta: { text: 'Home', icon: 'mdi-home' },
            },

            {
                name: 'simcards',
                path: '/simcards',
                component: () => import(/* webpackChunkName: "views" */ '../views/simcards.vue'),
                meta: { text: 'Simcards', icon: 'mdi-sim' },
            },
            {
                name: 'smartsims',
                path: '/smartsims',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'SmartSims', icon: 'mdi-file-account', active: false },
                children: [
                    {
                        name: 'smartsims_overview',
                        path: '/smartsims/overview',
                        component: () => import(/* webpackChunkName: "views" */ '../views/smartsims/overview.vue'),
                        meta: { text: 'Overview', icon: 'mdi-view-list' },
                    },
                    {
                        name: 'smartsims_activate',
                        path: '/smartsims/activate',
                        component: () => import(/* webpackChunkName: "views" */ '../views/smartsims/activate.vue'),
                        meta: { text: 'Activate', icon: 'mdi-check-bold' },
                    },
                    {
                        name: 'smartsims_wallet',
                        path: '/smartsims/wallet',
                        component: () => import(/* webpackChunkName: "views" */ '../views/smartsims/wallet.vue'),
                        meta: { text: 'Wallet', icon: 'mdi-wallet' },
                    },
                ],
            },
            {
                name: 'lora',
                path: '/lora',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'LoRa', icon: 'mdi-router-wireless', active: false },
                children: [
                    {
                        name: 'devices',
                        path: '/lora/devices',
                        component: () => import(/* webpackChunkName: "views" */ '../views/lora/devices.vue'),
                        meta: { text: 'Devices', icon: 'mdi-battery-charging-wireless-50' },
                    },
                    {
                        name: 'messages',
                        path: '/lora/messages',
                        component: () => import(/* webpackChunkName: "views" */ '../views/lora/messages.vue'),
                        meta: { text: 'Messages', icon: 'mdi-message-text' },
                    },
                ],
            },
            {
                name: 'bundles',
                path: '/bundles',
                component: () => import(/* webpackChunkName: "views" */ '../views/bundles.vue'),
                meta: { text: 'Bundles', icon: 'mdi-view-dashboard-outline' },
            },
            {
                name: 'switch',
                path: '/switch/:iccids?',
                component: () => import(/* webpackChunkName: "views" */ '../views/switch.vue'),
                meta: { text: 'Network Switch', icon: 'mdi-satellite-uplink' },
            },
            {
                name: 'triggers',
                path: '/triggers',
                component: () => import(/* webpackChunkName: "views" */ '../views/triggers.vue'),
                meta: { text: 'Triggers', icon: 'mdi-alarm' },
            },
            {
                name: 'diagnostics',
                path: '/diagnostics',
                component: () => import(/* webpackChunkName: "views" */ '../views/empty.vue'),
                meta: { text: 'Diagnostics', icon: 'mdi-clipboard-pulse', active: false },
                children: [
                    {
                        name: 'diagnostics_audit',
                        path: '/diagnostics/audit',
                        component: () => import(/* webpackChunkName: "views" */ '../views/diagnostics/audit.vue'),
                        meta: {
                            text: 'Audit Logs',
                            icon: 'mdi-text-box-outline',
                        },
                    },
                    {
                        name: 'diagnostics_scan',
                        path: '/diagnostics/scan/:iccid?',
                        component: () => import(/* webpackChunkName: "views" */ '../views/diagnostics/scan.vue'),
                        meta: { text: 'Scan', icon: 'mdi-magnify-scan' },
                    },
                    {
                        name: 'diagnostics_sms',
                        path: '/diagnostics/sms',
                        component: () => import(/* webpackChunkName: "views" */ '../views/diagnostics/sms.vue'),
                        meta: { text: 'Send Test SMS', icon: 'mdi-cellphone-message' },
                    },
                    {
                        name: 'diagnostics_country',
                        path: '/diagnostics/country',
                        component: () => import(/* webpackChunkName: "views" */ '../views/diagnostics/country.vue'),
                        meta: { text: 'Country History', icon: 'mdi-map-marker-distance' },
                    },
                ],
            },
            {
                name: 'downloads',
                path: '/downloads',
                component: () => import(/* webpackChunkName: "views" */ '../views/downloads.vue'),
                meta: { text: 'Downloads', icon: 'mdi-file-download-outline' },
            },
            {
                name: 'order',
                path: '/order/:order_id?',
                component: () => import(/* webpackChunkName: "views" */ '../views/order.vue'),
                meta: { text: 'Order', icon: 'mdi-cart', hidden: true },
            },
            {
                name: 'support',
                path: '/support',
                component: () => import(/* webpackChunkName: "views" */ '../views/support.vue'),
                meta: { text: 'Support', icon: 'mdi-lifebuoy', hidden: true },
            },
        ],
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "views" */ '../views/404.vue'),
    },
]

const router = new VueRouter({
    mode: 'history',
    saveScrollPosition: true,
    base: __dirname,
    routes,
})

router.afterEach((to, from) => {
    store.dispatch('api/reset')
})

export default router

function requireAuth(to, from, next) {
    // If this fails, we will be logged out
    store.dispatch('api/call', {
        url: '/ping',
    })

    let views = store.getters['session/rules'].views
    if (typeof views === 'undefined') {
        next('/logout')
    } else if (views == 'all' || views.includes(to.name)) {
        next()
    } else {
        next('/403')
    }
}
