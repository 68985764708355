import Vue from 'vue'
import CustomConfig from '@/config'
import App from './app.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueTimers from 'vue-timers'
import VueMeta from 'vue-meta'
import FlagIcon from 'vue-flag-icon'
import { Flag } from 'vue-flag-icon/components'
import Filters from './filters'
import vuetify from './plugins/vuetify'

Vue.use(CustomConfig)
Vue.use(VueTimers)
Vue.use(VueMeta)
Vue.use(FlagIcon)

Vue.mixin({
    filters: Filters
})

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
