<template>
    <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: this.$cfg.title,
            link: [
                {
                    rel: 'stylesheet',
                    href: `https://fonts.googleapis.com/css?family=${this.$cfg.theme.google_font}`
                },
                {
                    rel: 'stylesheet',
                    href: `/themes/${this.$cfg.theme.folder}/style.css`
                },
                { rel: 'shortcut icon', href: `/themes/${this.$cfg.theme.folder}/favicon.ico`, type: 'image/x-icon' }
            ],
            script: [{ src: `/themes/${this.$cfg.theme.folder}/custom.js`, async: true, defer: true }]
        }
    }
}
</script>
