import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import session from './modules/session'
import api from './modules/api'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        session,
        api
    },
    plugins: [createPersistedState(
        {
            key: 'store',
            paths: ['session']
        }
    )]
})